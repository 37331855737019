import * as waxjs from "@waxio/waxjs/dist";
import axios from "axios";

const wax = new waxjs.WaxJS(process.env.VUE_APP_WAX_API_ENDPOINT, null, null, false);

export default class WaxService {
	constructor() { }

	async login() {
		try {
			const userAccount = await wax.login();
			// authenticate user on our server
			var response = await axios.post(`${process.env.VUE_APP_BASE_API_URL}members/authenticate`, {
				username: userAccount,
			})
			if(response.status == 200){
				localStorage.setItem("userGuid", response.data.userGuid);
				return userAccount
			}
		}
		catch (e) {
			console.log(e)
		}

	}

}