import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Gift from './views/Gift.vue'
import Claim from './views/Claim.vue'
import HowItWorks from './views/HowItWorks.vue'
import MyGifts from './views/MyGifts.vue'
import MyClaims from './views/MyClaims.vue'

Vue.use(Router)

let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
            meta: {
                title: 'bountyblok.io',
            }
        },
        {
            path: '/howitworks',
            name: 'HowItWorks',
            component: HowItWorks,
            meta: {
                title: 'bountyblok.io'
            }
        },
        {
            path: '/gift/:assetID?',
            name: 'Gift',
            component: Gift,
            meta: {
                requiresAuth: true,
                title: 'bountyblok.io',
            }
        },
        {
            path: '/gift/:assetType?/:assetID?',
            name: 'Gift',
            component: Gift,
            meta: {
                requiresAuth: true,
                title: 'bountyblok.io',
            }
        },
        {
            path: '/claim/:giftclientid?/:giftid',
            name: 'Claim',
            component: Claim,
            meta: {
                title: 'bountyblok.io',
            }
        },
        {
            path: '/claim',
            name: 'Claim',
            component: Claim,
            meta: {
                title: 'bountyblok.io',
            }
        },
        {
            path: '/mygifts',
            name: 'MyGifts',
            component: MyGifts,
            meta: {
                requiresAuth: true,
                title: 'bountyblok.io',
            }
        },
        {
            path: '/myclaims',
            name: 'MyClaims',
            component: MyClaims,
            meta: {
                requiresAuth: true,
                title: 'bountyblok.io',
            }
        },
    ]
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('userGuid') == null) {
            next({
                path: `/`,
                query: { returnUrl: to.fullPath },
                params: { nextUrl: to.fullPath }
            })
        }
        else {
            next()
        }
    }
    else {
        next()
    }
})

export default router
