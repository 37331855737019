<template>
	<div class="my-claims">
		<div class="container is-max-desktop">
			<div class="columns is-full-height is-centered is-gapless is-desktop">
				<div class="column is-12-tablet is-11-desktop">
					<div class="content">
						<h2>My Claims</h2>
						<div class="table-container">
							<table class="table is-bordered is-fullwidth">
								<thead>
									<tr>
										<th>From</th>
										<th>NFT (Asset ID)</th>
										<th>Date</th>
										<th>TX</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="gift in myclaims" v-bind:key="gift.AssetID">
										<td>{{gift.fromName}}</td>
										<td>
											<a target="_blank" :href="getClaimUrl(gift.userGiftID,gift.isQRCampaign)">{{gift.assetID | truncate(10,'...')}}</a>
										</td>
										<td>{{gift.claimDate | moment("MMM Do, YYYY, h:mm a")}}</td>
										<td>
											<a target="_blank" :href="`${domainSettings.TxExplorerUrl}/${gift.transactionID}`">
												{{gift.transactionID | truncate(6,'')}}
											</a>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<nav v-if="showPrevious || showNext" class="pagination is-rounded" role="navigation" aria-label="pagination">
							<a v-if="showPrevious" class="pagination-previous is-uppercase has-text-weight-bold" @click="fetchGifts('previous')">Previous</a>
							<a v-if="showNext" class="pagination-next is-uppercase has-text-weight-bold" @click="fetchGifts('next')">Next page</a>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import UserGiftService from "../core/services/user-gift.service";

export default {
	props:{
        accountName: {
			type: String,
			required: true
        },
        domain:{
             type: Number,
             required: true
        },
        domainSettings:{
            type: Object
        }
    },
	data() {
		return {
			pageSize:10,
			pageNo:0,
			myclaims:[],
			showNext:true,
            showPrevious:true,
            vueAppUrl: process.env.VUE_APP_URL,
            waxUrl:process.env.VUE_APP_WAX_URL,
            theme: this.domainSettings.WebsiteName
		};
	},
	methods: {
        getClaimUrl(id,isQRCampaign){
            if(this.$route.query.id){
           return `${this.domainSettings.ClaimUrl}/${this.$route.query.id}/${id}`
            }
            else{
                if(isQRCampaign){
                    return `${this.domainSettings.ClaimUrl}?t=${id}`
                }
                else{
                    return `${this.domainSettings.ClaimUrl}/${id}`
                }
                 
            }
        },
		fetchGifts(direction){
			if(direction == 'previous'){
				if(!this.showPrevious){
					return;
				}
				this.pageNo = +this.pageNo - 1
			}
			else if(direction == 'next'){
				if(!this.showNext){
					return;
				}
				this.pageNo = +this.pageNo + 1
			}
		
			let service = new UserGiftService();
			service.getUserClaims(this.pageNo,this.pageSize).then((res) => {
			if(res.status == 200){
				this.myclaims = res.data.claims
				this.showNext = res.data.showNext
				this.showPrevious = res.data.showPrevious
			}
		})
		.catch((error) => {
			console.log(error)
		});
		}
	}
	,
	mounted() {
		// if not logged in
		if(!localStorage.getItem('userGuid')){
			this.$router.push(`/?id=${this.$route.query.id}`)
		}
		this.fetchGifts(null)
	},
};
</script>

<style scoped lang="scss">
$padding: 60px;

.my-claims {
	position: relative;
	z-index: 30;

	@media screen and (min-width: $desktop) {
		margin-top: $padding;
		margin-bottom: $padding * 2;
	}

	@media screen and (max-width: $desktop) {
		overflow-x: hidden;
		overflow-y: visible;
		padding: 40px 30px;
	}

	h2 {
		margin-bottom: 25px;
		color: $link;
	}

	.columns.is-full-height {
		min-height: calc(100vh - #{$navbar-height} - #{$footer-height} - #{$padding});

		@media screen and (max-width: 766px) {
			min-height: calc(100vh - #{$navbar-height} - 224px - 80px);
		}
	}

	table.table {

		thead tr th {
			text-align: left;
		}

		@media screen and (max-width: 766px) {
			tr td,
			tr th {
				white-space: nowrap;
			}
		}
	}

	.pagination {

		.pagination-previous,
		.pagination-next {
			text-align: center;

			@media screen and (max-width: 766px) {
				margin-left: 0;
				margin-right: 10px;
			}

			&:hover {
				background: $link;
			}
		}

		.pagination-next {
			margin-left: auto;

			@media screen and (max-width: 766px) {
				margin-left: 10px;
				margin-right: 0;
			}
		}
	}
}
</style>