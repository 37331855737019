export default class UserGiftModel {
    constructor() {
        this.FromName = ""
        this.CollectionName = ""
        this.TemplateID = ""
        this.Gifts = []
        this.Message = ""
        this.UserGuid = ""
        this.DeliveryBy = 1
        this.TransactionID = ""
        this.AssetMintNumber = ""
        this.AssetName = ""
        this.GiftClientID = ""
        this.AssetImg = ""
        this.AssetType = ""
        this.ImgHash = ""
    }
}

// export const enum DeliveryType {
//     Email = 1,
//     Text = 2
// }


