export const ASSETTYPE = Object.freeze({
    AA: 1,
    SA: 2
});

export const DOMAIN = Object.freeze({
    WAX: 1,
    GYB: 2,
    MEME: 3,
    NEWDEX: 4,
});
export const GYBPROVIDER = Object.freeze({
    ARKANE: 1,
    METAMASK: 2
});

export const DELIVERYTYPE = Object.freeze({
	EMAIL: 1,
	TEXT: 2,
    TWITTERDM: 3,
    DISCORDDM:4,
});